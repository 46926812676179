<template>
  <dashboard>
    
    <el-row :gutter="15">
      <el-col :xs="24">
        <div class="jobfeed">
          <el-card class="job">
            <h4 class="ml-3 mb-1 mt-3 bold orange-text">Job Search</h4>
    <p class="ml-3 mb-3 small">Please fill in the keywords that match the skills or the job you are looking for.</p>
    <hr>
            <div class="search">
              <el-input v-model="filters.keywords" placeholder="">
                <el-button slot="append" icon="el-icon-search" type="warning"> Keyword Search</el-button>
              </el-input>
              <div class="mt-3">
                <el-row :gutter="15">
                  <el-col :xs="24" :md="8" class="mb-xs-3">
                    <p class="small mb-2">Type</p>
                    <job-type-select v-model="filters.job_type" />
                  </el-col>
                  <el-col :xs="24" :md="16">
                    <p class="small mb-2">Price (minimum to maximum)</p>
                    <!-- <el-row :gutter="15">
                      <el-col :span="12">
                        <el-row :gutter="15">
                          <el-col :span="4">
                            <p class="mt-2 mb-0 small bold grey-text">Min</p>
                          </el-col>
                          <el-col :span="20">
                            <el-input-number v-model="filters.price_min" class="w-100" :precision="2" :step="0.1" :controls="false" />
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="12">
                        <el-row :gutter="15">
                          <el-col :span="4">
                            <p class="mt-2 mb-0 small bold grey-text">Max</p>
                          </el-col>
                          <el-col :span="20">
                            <el-input-number v-model="filters.price_max" class="w-100" :precision="2" :step="0.1" :controls="false" />
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row> -->
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bold">US$</span>
                      </div>
                      <input v-model="pmin" v-cleave="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" type="text" class="form-control" placeholder="Minimum">
                      <input v-model="pmax" v-cleave="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" type="text" class="form-control" placeholder="Maximum">
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <hr class="mb-3">
            <div class="body">
              <div class="mx-3">
                <p class="small text-right">Displaying {{ jobs.length }} out of {{ totalResults }} jobs</p>
              </div>
              <div v-if="jobs" class="">
                <freelancer-job-list :jobs="jobs" />
              </div>
            </div>
            <div v-if="totalResults > perPage" class="job-pagination mx-auto mb-4">
              <el-pagination
                background
                :current-page.sync="filters.page"
                layout="prev, pager, next"
                :total="totalResults"
                :page-size="perPage"
                @current-change="pageChange"
              />
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import _ from 'lodash'
import Cleave from 'cleave.js'
import Dashboard from '~/components/Dashboard/Dashboard'
import Job from '~/models/Jobs/FreelancerJob'
import handleError from '~/helpers/handleError'
import JobTypeSelect from '~/components/Jobs/JobTypeSelect'
import FreelancerJobList from '~/components/Jobs/Freelancer/List'

export default {
  components: { Dashboard, JobTypeSelect, FreelancerJobList },

  data () {
    return {
      filters: {
        keywords: '',
        job_type: '',
        price_min: 0,
        price_max: 0,
        page: 1,
      },
      jobs: [],
      errors: {},
      totalResults: 0,
      pmin: '',
      pmax: '',
      perPage: 1
    }
  },

  directives: {
    cleave: {
      inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
      update: (el) => {
          const event = new Event('input', {bubbles: true});
          setTimeout(function () {
              el.value = el.cleave.properties.result
              el.dispatchEvent(event)
          }, 100);
      }
    }
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        this.search()
      },
    },
    pmin (v) {
      // const result = v.replace(/\D/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // this.$nextTick(() => this.pmin = result)
      if (v) this.filters.price_min = parseFloat(v.replace(/,/g, ''))
      else this.filters.price_min = 0
    },
    pmax (v) {
      // const result = v.replace(/\D/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // this.$nextTick(() => this.pmax = result)
      if (v) this.filters.price_max = parseFloat(v.replace(/,/g, ''))
      else this.filters.price_max = 0
    }
  },

  created () {
    if(this.$route.query.page) {
      this.filters.page = parseInt(this.$route.query.page)
    }
    this.search()
  },

  methods: {
    pageChange(page) {
      window.history.pushState(null, null, `?page=${page}`)
      this.search()
    },

    search: _.debounce(function () {
      Job.search(this.filters).then(({ data }) => {
        this.jobs = data.data
        this.totalResults = data.meta.total
        this.perPage = data.meta.per_page
      }).catch(error => {
        if (! error.response) {
          return handleError(error)
        }

        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        }
      })
    }, 800),
  },
}

</script>
<style lang="scss" scoped>
  .form-control {
    font-size: 14px;
    height: calc(1.5em + .75rem + 5px);
  }
  .form-control:focus, .form-control:active { 
    box-shadow: none;
    outline: none;
    border: 1px solid #E87722 !important;
  }
</style>
<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
      // background-color: #f9f9f9;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;

    }
    .el-pagination {
      text-align: center;
    }
  }
@media (max-width: 767px) {
  .mb-xs-3 {
    margin-bottom: 1rem;
  }
}
</style>
