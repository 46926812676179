<template>
  <div>
    <el-tag
      v-for="s in list" :key="s.skill_id"
      type="info"
      size="small"
      class="mr-2 mt-2"
      v-text="s.skill_name ? s.skill_name : s.skill.skill_name"
    />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
</script>
