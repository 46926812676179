export default {
  filters: {
    capitalizeFirst (string) {
      if (! string) {
        return ''
      }
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },

  methods: {
    jobPricing (job) {
      const min = parseFloat(job.price_min)
      const max = parseFloat(job.price_max)
      const minLocale = min.toLocaleString()
      const maxLocale = max.toLocaleString()

      if (min && ! max) {
        return 'US$' + minLocale
      }

      if (min && max) {
        return 'US$' + minLocale + ' - US$' + maxLocale
      }

      return 'To be discussed'
    },
  },
}
