import Job from '~/models/Jobs/Job'

export default class FreelancerJob extends Job {
  static get resource () {
    return 'freelancer/jobs'
  }

  static applied (page) {
    return this.axios().get('applied?page=' + page)
  }

  static bookmarked (page) {
    return this.axios().get('bookmarked?page=' + page)
  }
}
