<template>
  <div>
    <div v-for="(j, i) in jobs" :key="j.job_id" class="job-row">
      <el-row :gutter="20">
        <el-col v-if="j.employer && j.employer.company && j.employer.company.logo" :span="3" class="">
          <div class="image-card">
            <div class="image-border">
              <div class="image-content" :style="`background-image: url(${companyLogo(j.employer.company)});`">
                <!-- <img :src="companyLogo(j.employer.company)" alt="..." class="w-100"> -->
              </div>
            </div>
          </div>
        </el-col>
        <el-col v-else :span="3" class="">
          <img src="/images/no-logo.png" alt="..." class="w-100" style="border-radius: 5px; border: 1px solid rgba(0,0,0,.1); box-shadow: 0 0px 2px 1px rgba(0,0,0,.05);">
        </el-col>
        <el-col :span="21">
          <router-link :to="`/freelancer/jobs/${j.job_id + tempCountId}`">
            <div class="">
              <h6 class="bold mb-1">{{ j.title }}</h6>
              <p class="small mb-2">
                <span class="mr-2" v-text="jobPricing(j)" />
                <el-tag size="mini" effect="plain" type="info" style="text-transform: capitalize" v-text="j.job_type" />
              </p>
              <p class="small description mb-1">{{ overview[i] | trimHtml | trimWords }}</p>
              <p class="small mt-3">
                <span class="mb-2">Required skills</span>
                <skills-list :list="j.skill" />
              </p>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import showsCompanyLogo from '~/mixins/showsCompanyLogo'
import SkillsList from '~/components/Skills/List'
import showsJobDetails from '~/mixins/showsJobDetails'
import trimmmers from '~/mixins/filters/trimmers'

export default {
  components: { SkillsList },
  mixins: [showsCompanyLogo, showsJobDetails, trimmmers],
  data() {
    return {
      tempCountId: 10000
    }
  },
  computed: {
    overview () {
      if (!this.jobs && !this.jobs.length) {
        return []
      }
      return this.jobs.map((item) => {
        if (item.intro) {
          return item.intro
        } else {
          return item.description
        }
      })
    }
  },
 props: {
    jobs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .image-card {
    padding: 5px;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .image-border {
    position: relative;
    width: 100%;
  }
  .image-border:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/
  }
  .image-border .image-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #fff;
  /*   display: flex;
    align-items: center; */
    line-height:100%;
    height:100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size:contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  img {
    padding: 5px;
    margin: auto;
    display: block;
  }
</style>
