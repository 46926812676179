export default {
    filters: {
        trimWords (value, length = 130, sufix = '...') {
            var maxLength = length // maximum number of characters to extract
            var trimmedString = value.substr(0, maxLength);
            if (value.length > trimmedString.length) {
                return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + sufix
            }
            return value
        },
        trimHtml (v) {
            return v.replace(/<\/?[^>]+(>|$)/g, "")
        }
    }
}